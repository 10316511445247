import React from "react"
import { makeStyles } from "@material-ui/core"
import Link from "@material-ui/core/Link"
import Typography from "@material-ui/core/Typography"

const useStyles = makeStyles((theme) => ({
  footerContainer: {
    marginTop: theme.spacing(3),
  },
  footer: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
    backgroundColor: theme.palette.primary.main,
    minHeight: 60,
    padding: theme.spacing(2),
    color: theme.palette.primary.contrastText,
  },
  links: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    "& > :not(:first-child)": {
      marginLeft: theme.spacing(2),
    },
  },
  link: {
    color: theme.palette.primary.contrastText,
  },
}))

const Footer = (props) => {
  const classes = useStyles()

  return (
    <div className={classes.footerContainer}>
      {props.before}
      <div className={classes.footer}>
        <div className={classes.links}>
          <Link
            href={"https://www.ase.org.uk/terms"}
            target="_blank"
            className={classes.link}
            variant="body1"
          >
            Terms & Conditions
          </Link>
          <Link
            href={"https://www.ase.org.uk/privacy-policy"}
            target="_blank"
            className={classes.link}
            variant="body1"
          >
            Privacy Policy
          </Link>
          <Link
            href={"https://www.ase.org.uk/libraries"}
            target="_blank"
            className={classes.link}
            variant="body1"
          >
            Libraries
          </Link>
        </div>
        <Typography
          component="span"
          variant="body2"
          style={{ whiteSpace: "pre-line", textAlign: "center" }}
        >
          {`
          The Association for Science Education. Registered address: 483 Green Lanes, London, N13 4BS
          Email info@ase.org.uk Phone: +44 (0) 1707 283000 
          Registered charity no: 313123/SC042473`}
        </Typography>
      </div>
    </div>
  )
}

export default Footer
