import React, { useState } from "react"
import {
  makeStyles,
  Select,
  FormControl,
  MenuItem,
  TextField,
  Button,
  InputAdornment,
  IconButton,
} from "@material-ui/core"

import GridListButtons from "./GridListButtons"
import { Close } from "@material-ui/icons"

const useStyles = makeStyles((theme) => ({
  selectBar: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "flex-end",
    [theme.breakpoints.only("xs")]: {
      justifyContent: "center",
    },
  },
  inputSelects: {
    display: "flex",
  },
  formControl: {
    margin: theme.spacing(1),
    minWidth: 160,
  },
}))

const SortBar = ({
  value,
  onChange,
  onViewChange,
  viewValue,
  visable,
  onSearch,
  searchDefault,
}) => {
  const classes = useStyles()
  const [searchValue, setSearchValue] = useState(searchDefault)

  const handleChange = (event) => {
    onChange("issueNo", event.target.value)
  }
  return (
    <div className={classes.selectBar}>
      <div className={classes.inputSelects}>
        {onSearch && (
          <div
            style={{
              margin: "8px",
              display: "flex",
              alignItems: "end",
              gap: "16px",
            }}
          >
            <FormControl style={{ flex: 1 }}>
              Search
              <TextField
                value={searchValue}
                onChange={(e) => setSearchValue(e.target.value)}
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">
                      {searchValue && searchValue !== "" && (
                        <IconButton
                          onClick={() => {
                            setSearchValue("")
                            onSearch("")
                          }}
                        >
                          <Close />
                        </IconButton>
                      )}
                    </InputAdornment>
                  ),
                }}
              />
            </FormControl>
            <Button variant="contained" onClick={() => onSearch(searchValue)}>
              Search
            </Button>
          </div>
        )}

        {onChange && (
          <FormControl className={classes.formControl}>
            Sort By:
            <Select
              labelId="select-order-label"
              name="select-order"
              value={value}
              onChange={handleChange}
              disabled={!!searchValue && searchValue !== ""}
            >
              <MenuItem value={"asc"}>Issue No. Asc</MenuItem>
              <MenuItem value={"desc"}>Issue No. Desc</MenuItem>
            </Select>
          </FormControl>
        )}
      </div>

      {visable && (
        <div className={classes.inputSelects}>
          <GridListButtons value={viewValue} onChange={onViewChange} />
        </div>
      )}
    </div>
  )
}

export default SortBar
