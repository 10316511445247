import React, { useEffect, useMemo } from "react"
import { makeStyles, Typography } from "@material-ui/core"
import { Redirect, useParams, useLocation, useHistory } from "react-router-dom"
import * as QueryString from "query-string"
import NoAccessToResource from "components/NoAccessToResource"

import { useLibrary } from "hooks/Library"
import SortBar from "components/SortBar"
import useDisplayMethod from "hooks/useDisplayMethod"
import IssueCard from "components/IssueCard"
import SubHeader from "components/SubHeader"
import GridWrapper from "components/GridWrapper"
import Pagination from "@material-ui/lab/Pagination"

import * as issueApi from "api/issue"
import useFetch from "hooks/useFetch"

const defaultParams = {
  limit: 15,
  order: "issueNo",
  dir: "desc",
  page: 0,
}

const useStyles = makeStyles((theme) => ({
  root: {},
  description: {
    padding: theme.spacing(1),
    textAlign: "justify",
  },
  paginationWrapper: {
    display: "flex",
    justifyContent: "center",
    marginTop: theme.spacing(2),
  },
}))

const useQuery = (defaultParams) => {
  const { search } = useLocation()

  const sortParams = useMemo(() => ({
    ...defaultParams,
    ...QueryString.parse(search),
  }), [defaultParams, search])
  
  const { push } = useHistory()

  const addQuery = (params) => {
    const query = QueryString.stringify({ ...sortParams, ...params })
    push({
      search: query,
    })
  }

  const setQuery = (params) => {
    const query = QueryString.stringify(params)

    push({
      search: query,
    })
  }

  return {
    params: sortParams,
    addQuery,
    setQuery,
    initParams: QueryString.parse(search),
  }
}

const Journal = ({ description }) => {
  const classes = useStyles()
  const { changeViewStyle, viewStyle, isSmallDevice } = useDisplayMethod()
  const { journalName } = useParams()
  const [submit, isLoading, data, error] = useFetch()
  const { hasAccess } = useLibrary()
  const { params, addQuery, setQuery, initParams } = useQuery(defaultParams)

  useEffect(() => {
    // get list of issue for a given journal
    submit(() => issueApi.getAll(journalName, params))
  }, [params, journalName, submit])

  const handleSort = (order, dir) => {
    addQuery({ order, dir, page: 0 })
  }

  const handlePageChange = (event, page) => {
    addQuery({ page: page - 1 })
  }

  const handleSearch = (searchValue) => {
    if (searchValue && searchValue !== "") {
      setQuery({ search: searchValue })
    } else {
      setQuery({ page: 0, dir: "desc", limit: 15, search: undefined })
    }
  }

  const pageCount = React.useMemo(() => {
    if (!data) return 0

    const res = Math.floor(
      data.issues.total % defaultParams.limit !== 0
        ? data.issues.total / defaultParams.limit + 1
        : data.issues.total / defaultParams.limit
    )
    return res
  }, [data])

  if (error) {
    return <Redirect to="/404" />
  }
  if (isLoading) {
    return (
      <div className={classes.root}>
        <SubHeader />

        <SortBar
          onViewChange={changeViewStyle}
          viewValue={viewStyle}
          visable={!isSmallDevice}
        />

        <GridWrapper viewStyle={viewStyle}>
          <JournalSkelentons num={3} listMethod={viewStyle} />
        </GridWrapper>
      </div>
    )
  } else {
    if (data.journal.isFree || hasAccess(data.journal._id)) {
      return (
        <div className={classes.root}>
          <SubHeader title={data?.journal.name} subtitle={data.journal.title} />
          <Typography variant="body2" className={classes.description}>
            {data.journal.description}
          </Typography>

          <SortBar
            onViewChange={changeViewStyle}
            onSearch={handleSearch}
            searchDefault={initParams?.search ?? ""}
            viewValue={viewStyle}
            visable={!isSmallDevice}
            onChange={handleSort}
            value={params.dir}
          />

          <GridWrapper viewStyle={viewStyle}>
            {data.issues.docs.map((issue) => (
              <IssueCard
                key={`issue-${issue.issueNo}`}
                image={issue.coverImage}
                subtitle={issue.theme}
                issueNo={issue.issueNo}
                listOrientation={viewStyle}
                journalName={data.journal.title}
              />
            ))}
          </GridWrapper>
          <div className={classes.paginationWrapper}>
            <Pagination
              count={pageCount}
              page={parseInt(params.page) + 1}
              onChange={handlePageChange}
            />
          </div>
        </div>
      )
    } else {
      return <NoAccessToResource />
    }
  }
}

const JournalSkelentons = ({ num, listMethod }) => {
  let skelentons = []

  for (let i = 0; i < num; i++) {
    skelentons[i] = (
      <IssueCard
        key={"loading-" + i}
        listOrientation={listMethod}
        isloading={true}
      />
    )
  }
  return skelentons
}

export default Journal
